<!-- 错题重答 -->
<style lang="scss" scoped>
@import '@/assets/styles/questionsItem.scss';
.page{
    position: relative;
	width: 100%;
    height: 100%;
    padding-bottom: 80px;
    box-sizing: border-box;
}
*{
    box-sizing: border-box;
}
.u-nav-slot-left{
	.number{
		font-size: 36px;
		font-weight: bold;
		color: #3D3D3D;
	}
}
.lineProgress{
	width: 240px;
}
.content{
	padding: 20px;
	overflow: auto;
	height: calc(100vh - 220px);
	
}
// 小题列表
.answerPage{
	width: 100%;
	height: 600px;
	max-height: 80vh;
	position: relative;
	overflow: auto;
	.backimg{
		width: 100%;
		position: fixed;
		right: 0;
		top: 0;
		height: 100vh;
		z-index: 0;
	}
	.topicList{
		position: relative;
		z-index: 5;
		.paperName{
            font-size: 18px;
            font-weight: 400;
            color: #FF4D4F;
            line-height: 34px;
            width: 100%;
            text-align: center;
            margin: 10px 0;
		}
		.questions{
			width: 100%;
			background-color: #FFFFFF;
			margin-bottom: 32px;
			padding: 0px 6px 39px 32px;
			display: inline-block;
			overflow-x: hidden;
			border-radius: 14px;
			.type{
				font-size: 32px;
				font-weight: bold;
				color: #222222;
				line-height: 45px;
			}
			
		}
		.ul{
			width: 100%;
			display: inline-block;
			.item{
				float: left;
                margin-top: 20px;
                width: 34px;
                height: 34px;
                background: #E2E2E2;
                border-radius: 50%;
                margin-right: 26px;
                text-align: center;
                line-height: 34px;
                color: #FFFFFF;
                font-size: 18px;
                position: relative;
				&.opt::after{
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 37px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-left: -5px;
					background-color: $comBlue;
				}
				&:hover{
                    cursor: pointer;
					background-color: $comBlue;
				}
                
			}
		}
	}
	.flotageBtn{
		background-color: rgba(255,255,255,0.8);
	}
}
.movableArea{
	width:750px;
	height:50vh;
	position:fixed;
	bottom:0;
	pointer-events:none;
	z-index:1000;
	.movableView{
		top:auto;
		left:auto;
		pointer-events: auto;	//恢复鼠标事件
		position: absolute;
		right: 40px;
		bottom: 0;
		width: 108px;
		height: 108px;
	}
	.questionsAll{
		width: 108px;
		height: 108px;
		background-color: #CBEFDA;
		border-radius: 50%;
	}
}
.groupOperate{
	border-radius: 50px;
    padding: 0;
    overflow: hidden;
    width: 400px;
    margin: 15px auto 0;
	.btn{
		border-radius: 0;
		height: 46px;
		border-radius: 5px;

	}
}
.pictureList{
	width: 100%;
	padding: 32px 0 10px;
	display: inline-block;
	.item{
		width: 140px;
		height: 140px;
		border-radius: 8px;
		position: relative;
		margin-left: 15px;
		float: left;
		overflow: hidden;
		.img{
			width: 140px;
			height: 140px;
		}
		.iconfont{
			position: absolute;
			right: 0;
			top:0;
			font-size: 26px;
			opacity: 0.5;
			color: #FFFFFF;
			&:hover{
				cursor: pointer;
				opacity: 1;
			}
		}
		&:first-child{
			margin-left: 0;
		}
	}
}
.picture{
	display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 150px;
    margin: 0 auto;
	i{
		font-size: 26px;
		margin-right: 10px;
	}
}
.selectAnswer{
	padding: 20px 20px 0; 
	.h3{
		height: 22px;
		font-size: 14px;
		font-weight: bold;
		color: #444444;
		line-height: 22px;
	}
	.btns{
		padding: 10px 0;
		color: #444444;
		font-size: 16px;
		margin-right: 20px;
		.iconfont{
			color: #D8D8D8;
			font-size: 20px;
			font-weight: bold;
			margin-right: 6px;
		}
		&.opt{
			.iconfont{
				color: #FF4D4F;
			}
		}
		&:hover{
			cursor: pointer;
		}
	}
}

.showAnswer{
	position: absolute;
    right: 30px;
    top: 15px;
    width: 50px;
    font-size: 22px;
	&:hover{
		opacity: 0.8;
		cursor: pointer;
	}
}
.questions{
	.hintTitle{
		font-size: 14px;
		color: #888888;
		margin-top: 20px;
		margin-bottom: 10px;
	} 
	.li:hover{
		cursor: pointer;
		box-shadow: 0 0 8px 5px #D0E2F4;
	}
}

::v-deep .el-dialog__title{
    &::before{
        display: none;
    }
    padding-left: 0px;
} 
::v-deep .el-dialog--center .el-dialog__body{
	margin: 0;
	width: 100%;
	box-sizing: border-box;
	padding:25px 0;
}
.setNumber{
    text-align: center;
    .el-button{
        line-height: 40px;
        height: 40px;
        margin-top: 20px;
    }
} 
::v-deep .el-upload--text{
	height: 60px;
}

.office{
	margin:0 auto 30px;
	width: 100%;
	max-width: 800px;
}
</style>

<template>
    <section class="page">
        <div class="content">
			<div class="questions">
				<div class="topic" v-if="homeworkItem.syque_typ_id!=14">
					<div class="val">
						<div class="richblock" v-html="homeworkItem.stque_title"></div>
					</div>
					<span class="c-orange">（{{homeworkItem.syque_typ_name}}）</span>
                    <img class="selectimg" v-if="homeworkItem.stque_image" :src="homeworkItem.stque_image" alt="">
					<!-- <el-image v-if="homeworkItem.stque_image" style="width: 200px; height: 100px" :src="homeworkItem.stque_image" :preview-src-list="[homeworkItem.stque_image]"></el-image> -->
				</div>
				
				<div class="office"  v-if="homeworkItem.syque_typ_id==99&&homeworkItem.stque_att_paper_url">
					<vueOffice v-model="homeworkItem.stque_att_paper_url" type="pdf"></vueOffice>
				</div>
				<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
				<!-- 单选和判断 -->
				<div v-if="homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==2||homeworkItem.syque_typ_id==5">
					<div v-for="(item,index) in homeworkItem.children_question" :key="index">
						<!-- 判断单题的答案下标位置是否等于当前题目的选项值 getopt是否选中  getopt选中的并且已经提交的?(里面其中结果为正确?correct:mistake)  --> 
						<div class="li" :class="[getopt(item.stque_ite_code,homeworkItem.stque_stuanswer)?'opt':'',getopt(item.stque_ite_code,homeworkItem.stque_stuanswer)&&homeworkItem.stque_issure>0?(item.stque_ite_isanswer==10?'correct':'mistake'):'']" @click="homeworkItem.stque_issure==0?(clickPap(index,item.stque_ite_code)):''">
							<div v-if="item.stque_ite_title">
								<div class="flex">
									<div class="iconfont icona-dui">&#xe6db;</div>
									<div class="iconfont icona-cuo">&#xe6dc;</div>
									<div class="number">{{item.stque_ite_code}}.</div>
									<div>
										<div class="val">
											<div class="richinline" v-html="item.stque_ite_title"></div>
										</div>
										<img class="selectimg" v-if="item.stque_ite_image" :src="item.stque_ite_image" alt="">
										<!-- <el-image v-if="item.stque_ite_image" style="width: 200px; height: 100px" :src="item.stque_ite_image" :preview-src-list="[item.stque_ite_image]"></el-image> -->
									</div>
								</div>
							</div>
							<div class="flex" v-else>
								<div class="iconfont icona-dui">&#xe6db;</div>
								<div class="iconfont icona-cuo">&#xe6dc;</div>
								<div class="number">{{item.stque_ite_code}}.</div>
                                <img class="selectimg" v-if="item.stque_ite_image" :src="item.stque_ite_image" alt="">
								<!-- <el-image v-if="item.stque_ite_image" style="width: 200px; height: 100px" :src="item.stque_ite_image" :preview-src-list="[item.stque_ite_image]"></el-image> -->
							</div>
						</div>
					</div>
				</div>
				<!-- 填空 -->
				<div style="margin-bottom: 24px;" v-if="homeworkItem.syque_typ_id==3||homeworkItem.syque_typ_id==4">
					<div class="hintTitle" v-if="homeworkItem.syque_typ_id==4">请依次将填空题答案输入到下框中</div>
					<div v-for="(item,index) in homeworkItem.children_question" :key="index">
						<try-editor :key="homeworkItem.stque_id+'_'+index" v-model="homeworkItem.stque_stuanswer[index]" placeholder="点击输入"></try-editor>
						<!-- <input type="text" class="input" v-model="homeworkItem.stque_stuanswer[index]" :disabled="homeworkItem.stque_issure>0" placeholder="点击输入" placeholder-class="placeholder"> -->
					</div>
				</div>
				<!-- 8问答 -->
				<div style="margin-bottom: 24px;" v-if="homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8">
					<try-editor :key="homeworkItem.stque_id" v-model="homeworkItem.stque_stuanswer[0]" placeholder="点击输入"></try-editor>
					<!-- <textarea class="textarea" v-model="homeworkItem.stque_stuanswer[0]" :disabled="homeworkItem.stque_issure>0" placeholder="点击输入" placeholder-class="placeholder"></textarea> -->
				</div>
				<!-- 图片上传 -->
				<div v-if="homeworkItem.syque_typ_id==3||homeworkItem.syque_typ_id==4||homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8||homeworkItem.syque_typ_id==99">
					<div class="pictureList" v-if="homeworkItem.file">
						<div class="item" v-for="(item,index) in homeworkItem.file" :key="index">
							<img class="img" v-if="item" :src="item" alt="">
							<!-- <el-image v-if="item" style="width: 140px; height: 140px" :src="item" :preview-src-list="[item]"></el-image> -->
							<div class="iconfont" @click="deleteImg(index,1)" v-if="homeworkItem.stque_issure==0">&#xe676;</div>
						</div>
					</div>
				</div>
				<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 组合题 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
				<div v-if="homeworkItem.syque_typ_id==14">
					<div class="topic">
						<div class="val">
							<div class="richinline" v-html="homeworkItem.stque_title"></div>
						</div>
						<span class="c-orange">（{{homeworkItem.syque_typ_name}}）</span>
						<img class="selectimg" v-if="homeworkItem.stque_image" :src="homeworkItem.stque_image" alt="">
						<!-- <el-image v-if="homeworkItem.stque_image" style="width: 200px; height: 100px" :src="homeworkItem.stque_image" :preview-src-list="[homeworkItem.stque_image]"></el-image> -->
					</div>
					<div class="p-0-32">
						<div class="topic">
							<div class="val">
								<em class="richinline" v-html="groupworkItem.stque_title"></em> 
								<span class="c-orange">
								（{{groupworkItem.syque_typ_name}}）
								</span>
							</div>
                            <img class="selectimg" v-if="groupworkItem.stque_image" :src="groupworkItem.stque_image" alt="">
							<!-- <el-image v-if="groupworkItem.stque_image" style="width: 200px; height: 100px" :src="groupworkItem.stque_image" :preview-src-list="[groupworkItem.stque_image]"></el-image> -->
						</div>
						<!-- 单选和判断 -->
						<div v-if="groupworkItem.syque_typ_id==1||groupworkItem.syque_typ_id==2||groupworkItem.syque_typ_id==5">
							<div v-for="(item,index) in groupworkItem.question_child_item" :key="index">
								<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
								<div class="li" :class="[getopt(item.stque_ite_code,groupworkItem.stque_stuanswer)?'opt':'',getopt(item.stque_ite_code,groupworkItem.stque_stuanswer)&&groupworkItem.stque_issure>0?(item.stque_ite_isanswer==10?'correct':'mistake'):'']" @click="groupworkItem.stque_issure==0?(clickMinPap(index,item.stque_ite_code)):''">
									<div v-if="item.stque_ite_title">
										<div class="flex">
											<div class="iconfont icona-dui">&#xe6db;</div>
											<div class="iconfont icona-cuo">&#xe6dc;</div>
											<div class="number">{{item.stque_ite_code}}.</div>
											<div>
												<div class="val">
													<div class="richinline" v-html="item.stque_ite_title"></div>
												</div>
                                                <img class="selectimg" v-if="item.stque_ite_image" :src="item.stque_ite_image" alt="">
												<!-- <el-image v-if="item.stque_ite_image" style="width: 200px; height: 100px" :src="item.stque_ite_image" :preview-src-list="[item.stque_ite_image]"></el-image> -->
											</div>
										</div>
										
									</div>
									<div class="flex" v-else>
										<div class="iconfont icona-dui">&#xe6db;</div>
										<div class="iconfont icona-cuo">&#xe6dc;</div>
										<div class="number">{{item.stque_ite_code}}.</div>
                                        <img class="selectimg" v-if="item.stque_ite_image" :src="item.stque_ite_image" alt="">
										<!-- <el-image v-if="item.stque_ite_image" style="width: 200px; height: 100px" :src="item.stque_ite_image" :preview-src-list="[item.stque_ite_image]"></el-image> -->
									</div>
								</div>
							</div>
						</div>
						<!-- 填空 -->
						<div v-if="groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==4">
							<div class="hintTitle" v-if="groupworkItem.syque_typ_id==4">请依次将填空题答案输入到下框中</div>
							<div style="margin-bottom: 24px;" v-for="(item,index) in groupworkItem.question_child_item" :key="index">
								<try-editor :key="groupworkItem.stque_id+'_'+index" v-model="groupworkItem.stque_stuanswer[index]" placeholder="点击输入"></try-editor>
								<!-- <input type="text" class="input" v-model="groupworkItem.stque_stuanswer[index]" :disabled="groupworkItem.stque_issure>0" placeholder="点击输入" placeholder-class="placeholder"> -->
							</div>
						</div>
						<!--7计算 8问答 -->
						<div style="margin-bottom: 24px;" v-if="groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8">
							<try-editor :key="groupworkItem.stque_id" v-model="groupworkItem.stque_stuanswer[0]" placeholder="点击输入"></try-editor>
							<!-- <textarea class="textarea" v-model="groupworkItem.stque_stuanswer[0]" placeholder="点击输入" :disabled="groupworkItem.stque_issure>0" placeholder-class="placeholder"></textarea> -->
						</div>
						<!-- 图片上传 -->
						<div v-if="groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==4||groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8">
							<div class="pictureList" v-if="groupworkItem.file">
								<div class="item" v-for="(item,index) in groupworkItem.file" :key="index">
									<el-image v-if="item" style="width: 140px; height: 140px" :src="formatfile(item)" :preview-src-list="[formatfile(item)]"></el-image>
									<div class="iconfont" @click="deleteImg(index,2)">&#xe676;</div>
								</div>
							</div>
						</div>
						<!-- <div class="handle" v-if="groupworkItem.stque_issure==0&&(groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==4||groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8)">
							<el-upload class="picture" :action="$upload.imgAction" 
								:headers="$upload.header" 
								:show-file-list="false" 
								:accept="$upload.imgAccept" 
								:before-upload="$beforImgUpload" 
								:on-success="(res) =>{return uploadImgSuccess(res,2); }" 
								:on-exceed="() => { $message.warning('上传文件数量超出限制，最多上传3个'); }" 
								ref="imgUploader"
								multiple
								:limit="3" >
								<div class="combtn6">
									<i class="c-green el-icon-picture"></i>
									<div class="fw-b c-green">上传答案</div>
								</div>
							</el-upload>
						</div> -->
						<div class="flex_bet_align groupOperate">
							<div class="combtn3 btn" v-if="grouTopicIndex>0" @click="changeGrouPrior">
								<div class="iconfont">&#xe6d2;</div>
								上一题
							</div>
							<div class="combtn3 btn" v-else-if="papIndex>0" @click="changePrior()">
								<div class="iconfont">&#xe6d2;</div>
								上一大题
							</div>
							<div class="combtn4 btn" v-else>
								<div class="iconfont">&#xe6d2;</div>
								上一题
							</div>
							<div class="combtn3 btn" v-if="groupworkItem.stque_issure==0" v-loading="submitloading" @click="submit">
								提交
							</div>
							<div class="combtn3 btn" v-else-if ="(grouTopicIndex+1)<homeworkItem.children_question.length" @click="changeGrouNext()">
								下一题
								<div class="iconfont">&#xe6cf;</div>
							</div>
							<div class="combtn3 btn" v-else-if="homework_list.length>papIndex+1" @click="changeNext()">下一大题<div class="iconfont">&#xe6cf;</div></div>
							<div class="combtn4 btn" v-else>题已答完</div>
						</div>
					</div>
					<!-- 自行设置答题正确错误 -->
					<!-- <div class="selectAnswer" v-if="(groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==4||groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8)&&groupworkItem.stque_issure>0"> -->
					<div class="selectAnswer" v-loading="selectAnswerLoading" v-if="(groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==4||groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8)&&groupworkItem.stque_issure>0">
						<div class="h3" v-if="groupworkItem.stque_issure==1||groupworkItem.stque_issure==2">已完成答题，答题结果：</div>
						<div class="h3" v-else>请自行判断后选择答题结果</div>
						<div class="flex_align">
							<div class="btns flex_align" v-if="groupworkItem.stque_issure!=2" :class="groupworkItem.stque_issure==1?'opt':''" @click="groupworkItem.stque_issure!=1?setStqueIssure(1,2,groupworkItem.stque_id):''">
								<div class="iconfont" v-if="groupworkItem.stque_issure!=1&&groupworkItem.stque_issure!=2">&#xe683;</div>
                                <div class="iconfont icona-dui" v-else>&#xe6db;</div>
								正确
							</div>
							<div class="btns flex_align" v-if="groupworkItem.stque_issure!=1" :class="groupworkItem.stque_issure==2?'opt':''" @click="groupworkItem.stque_issure!=2?setStqueIssure(2,2,groupworkItem.stque_id):''">
								<div class="iconfont" v-if="groupworkItem.stque_issure!=1&&groupworkItem.stque_issure!=2">&#xe683;</div>
								<div class="iconfont icona-cuo" v-else>&#xe6dc;</div>
								错误
							</div>
						</div>
					</div>
					<!-- 答题正确图片 -->
				    <img class="encourageImg" v-if="groupworkItem.stque_issure==1" src="@assets/images/correctImg.png" alt="">
					<!-- 答题错误图片 -->
				    <img class="encourageImg" v-if="groupworkItem.stque_issure==1" src="@assets/images/studentbiehuixin.png" alt="">
					<div class="emphasis" v-if="groupworkItem.stque_issure>0">
						<div class="h2 flex_align">
							<div class="iconfont">&#xe6d9;</div>
							答案解析
						</div>
						<div class="h4" v-if="groupworkItem.syque_typ_id==1||groupworkItem.syque_typ_id==2||groupworkItem.syque_typ_id==5">
							正确答案：<span class="richinline" v-html="groupworkItem.sure_answer.join(',')"></span>
						</div>
						<div class="p richblock" v-if="groupworkItem.stque_analysis" v-html="groupworkItem.stque_analysis"></div>
					</div>
					<div class="emphasis" v-if="groupworkItem.stque_issure>0">
						<div class="h2 flex_align">
							<div class="iconfont">&#xe6da;</div>
							知识点
						</div>
						<div class="p" v-if="groupworkItem.tetea_kno_content">{{groupworkItem.tetea_kno_content}}</div>
						<div class="noexplain" v-else>暂无数据</div>
					</div>
				</div>
			</div>
			
			<!-- 自行设置答题正确错误 -->
			<div class="selectAnswer" v-loading="selectAnswerLoading"  v-if="(homeworkItem.syque_typ_id==3||homeworkItem.syque_typ_id==4||homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8||homeworkItem.syque_typ_id==99)&&homeworkItem.stque_issure>0">
			<!-- <div class="selectAnswer" v-if="(homeworkItem.syque_typ_id<9)&&homeworkItem.stque_issure>0"> -->
				<div class="h3">请自行判断后选择答题结果</div>
				<div class="flex_align">
					<div class="btns flex_align" v-if="homeworkItem.stque_issure!=2" :class="homeworkItem.stque_issure==1?'opt':''" @click="homeworkItem.stque_issure!=1?setStqueIssure(1,1,homeworkItem.stque_id):''">
						<div class="iconfont" v-if="homeworkItem.stque_issure!=1&&homeworkItem.stque_issure!=2">&#xe683;</div>
                        <div class="iconfont icona-dui" v-else>&#xe6db;</div>
						正确
					</div>
					<div class="btns flex_align" v-if="homeworkItem.stque_issure!=1" :class="homeworkItem.stque_issure==2?'opt':''" @click="homeworkItem.stque_issure!=2?setStqueIssure(2,1,homeworkItem.stque_id):''">
						<div class="iconfont" v-if="homeworkItem.stque_issure!=1&&homeworkItem.stque_issure!=2">&#xe683;</div>
						<div class="iconfont icona-cuo" v-else>&#xe6dc;</div>
						错误
					</div>
				</div>
			</div>
			<div v-if="homeworkItem.syque_typ_id!=14">
					<!-- 答题正确图片 -->
                <img class="encourageImg" v-if="homeworkItem.stque_issure==1" src="@assets/images/correctImg.png" alt="">
                <!-- 答题错误图片 -->
                <img class="encourageImg" v-if="homeworkItem.stque_issure==2" src="@assets/images/studentbiehuixin.png" alt="">
			</div>
			<!-- stque_issure结果 1正确 2错误  -->
			<div class="emphasis" v-if="homeworkItem.stque_issure>0">
				<div class="h2 flex_align">
					<div class="iconfont">&#xe6d9;</div>
					答案解析
				</div>
				<div class="office"  v-if="homeworkItem.syque_typ_id==99&&homeworkItem.stque_stexa_att_analysis_url">
					<vueOffice v-model="homeworkItem.stque_stexa_att_analysis_url" type="pdf"></vueOffice>
				</div>
				<div class="h4" v-if="homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==2||homeworkItem.syque_typ_id==5">
					正确答案：<span class="richinline" v-html="getResults(homeworkItem.children_question)"></span>  
				</div>
				<div class="p richblock" v-if="homeworkItem.stque_analysis" v-html="homeworkItem.stque_analysis"></div>
			</div>
			<div class="emphasis" v-if="homeworkItem.stque_issure>0&&homeworkItem.tetea_kno_content">
				<div class="h2 flex_align">
					<div class="iconfont">&#xe6da;</div>
					知识点
				</div>
				<div class="p" v-if="homeworkItem.tetea_kno_content">{{homeworkItem.tetea_kno_content}}</div>
			</div>
		</div>
		<div class="flotageBtn bg-c-f">
			<div class="combtn3 mr-24" v-if="papIndex>0" @click="changePrior()">上一题</div>
			<div class="combtn4 mr-24" v-else>无上一题</div>
			<template v-if="homework_list.length>0">
				<div class="combtn3" v-if="homeworkItem.stque_issure==0&&homeworkItem.syque_typ_id!=14" v-loading="submitloading" @click="submit">提交</div>
				<div class="combtn3" v-else-if="homework_list.length>papIndex+1" @click="changeNext()">下一题</div>
				<div class="combtn4" v-else @click="goPage()">题已答完</div>
                <div class="combtn3 showAnswer" @click="showAnswer=true">
                    <div class="iconfont">&#xe681;</div>
                </div>
			</template>
		</div>
        
        
		<!-- 显示答题进度 -->
		<el-dialog title="试卷目录" :visible.sync="showAnswer" width="50%" center>
			<div class="answerPage">
				<div class="topicList">
					<div class="paperName">点击切换试题</div>
					<div class="questions">
						<div class="ul">
							<div v-for="(item,index) in homework_list" :key="index" class="item" :class="papIndex==index?'opt':''" @click="clickQuestion(index)">{{index+1}}</div>
						</div>
					</div>
				</div>
				<div class="flotageBtn flex_content_center">
					<div class="combtn8" @click="showAnswer=false">关闭</div>
				</div>
			</div>
        </el-dialog>
		<el-dialog title="提示" :visible.sync="showGrasp" width="30%" center :show-close="false" v-loading="graspLoading" :close-on-press-escape="false" :close-on-click-modal="false">
			<div class="setNumber">
				<div>答对啦，是否已掌握？</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="graspTopic(20)">未掌握</el-button>
					<el-button type="primary" @click="graspTopic(30)">已掌握</el-button>
				</div>
			</div>
		</el-dialog>
    </section>
</template>

<script>
import { random_question,submit_question,stu_exactness,is_grasp} from "@api/home"
import { formatFile } from "@utils";
import vueOffice from "@comp/vueOffice"
export default {
    components: {
        vueOffice
    },
	computed: {
		formatfile() {
			return function (url) {
				return formatFile(url);
			};
		},
	},
    name: 'doExercise',
    data() {
        return {
            //科目id
            sysub_id:0,
            //显示小题列表
            showAnswer:false,
            //重做数量
            random_num:0,
            //所有试题 {stque_issure:0} 学生是否回答正确：1=是,2=否
            homework_list:[],
            //单题内容
            homeworkItem:{},
            //组合题单题内容
            groupworkItem:{},
            //题下标
            papIndex:0,
            //组合题小题下标
            grouTopicIndex:0,
            //总题数量
            allPapLength:0,
            //当前第几题
            itemPapindex:0,
            //上传答题图片
            file:[],
			//提交等待
			submitloading:false,
			//提交主观题选择对错等待
			selectAnswerLoading:false,
			// 掌握弹窗
			showGrasp:false,
			// 掌握弹窗等待
			graspLoading:false,
			//班级ID
			sccla_id:'',
        }
    },
    created() {
        this.sysub_id=this.$route.params.id
        this.random_num=this.$route.params.random_num
		this.sccla_id=this.$route.params.sccla_id
        this.getData();
    },
    methods: {
       
        /** 获取公开课列表数据 */
        async getData() {
            let params = {
                sysub_id:this.sysub_id,
                random_num:this.random_num,
				sccla_id:this.sccla_id
            }
            let { data } = await random_question(params);
            this.homework_list=data
            this.homeworkItem=data[0]
            //判断第一类是否为组合提
            if(data[0].syque_typ_id==14){
                this.groupworkItem=data[0].children_question[0]
            }
            this.$forceUpdate();
        },
        //提交答案
			async submit(){
				if(this.submitloading){
					return false
				}
				//非组合提
				if(this.homeworkItem.syque_typ_id!=14){
					let homeworkItem=this.homeworkItem
					let stque_stuanswer=homeworkItem.stque_stuanswer
					// 判断或单选
					if(homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==2||homeworkItem.syque_typ_id==5){
						if(stque_stuanswer.length<1){
							this.$message({
								message: '请选择答案',
								type: 'warning'
							});
							return false
						}
					}else{
						if(stque_stuanswer.length<1&&!homeworkItem.file){
							this.$message({
								message: '请填写答案或上传答题',
								type: 'warning'
							});
							return false
						}
					}
					this.submitloading=true
					let { data } = await submit_question({
						stque_id:homeworkItem.stque_id,
						stque_stuanswer:stque_stuanswer,
						stque_stuimages:homeworkItem.file?homeworkItem.file.toString():"",
						sccla_id:this.sccla_id,
					});
					homeworkItem.stque_issure=data.stque_issure
					//弹窗询问是否已掌握
					if(data.stque_issure==1){
						this.showGrasp=true
					}
					this.homework_list[this.papIndex]=homeworkItem
					this.homeworkIte=homeworkItem
					this.submitloading=false
				}else{
					let groupworkItem=this.groupworkItem
					//组合提
					if(groupworkItem.syque_typ_id==1||groupworkItem.syque_typ_id==2||groupworkItem.syque_typ_id==5){
						if(groupworkItem.stque_stuanswer.length<1){
							this.$message({
								message: '请选择答案',
								type: 'warning'
							});
							return false
						}
					}else{
						if(groupworkItem.stque_stuanswer.length<1&&!groupworkItem.file){
							this.$message({
								message: '请填写答案或上传答题',
								type: 'warning'
							});
							return false
						}
					}
					this.submitloading=true
					let { data } = await submit_question({
						stque_id:groupworkItem.stque_id,
						stque_stuanswer:groupworkItem.stque_stuanswer,
						stque_stuimages:groupworkItem.file?groupworkItem.file.toString():"",
						sccla_id:this.sccla_id,
					});
					groupworkItem.stque_issure=data.stque_issure
					//弹窗询问是否已掌握 是否已经成为未掌握
					if(data.stque_issure==1&&this.homework_list[this.papIndex].grasp!=20){
						this.showGrasp=true
					}
					this.homework_list[this.papIndex].children_question[this.grouTopicIndex]=groupworkItem
					this.submitloading=false
					this.setGroupworkItem(groupworkItem)
					
				}
			},
			//判断试题是否选中 answer答案  list
			getopt(answer,list){
				if(answer&&list&&list.length>0){
					return list.includes(answer)
				}else{
					return false
				}
			},
			
			//点击单选题
			clickPap(index,val){
				let homeworkItem=this.homeworkItem
				if(homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==5){
					//单选题或判断题
					homeworkItem.stque_stuanswer=[val]
				}else if(homeworkItem.syque_typ_id==2){
					//多选题
					if(this.getopt(val,homeworkItem.stque_stuanswer)){
						homeworkItem.stque_stuanswer.splice(homeworkItem.stque_stuanswer.indexOf(val),1)
					}else{
						homeworkItem.stque_stuanswer.push(val)
					}
				}
				this.homework_list[this.papIndex]=homeworkItem
				this.setHomeworkItem(homeworkItem)
				// 强行刷新视图
				// this.$forceUpdate();
				
			},
			//组合题选中
			clickMinPap(index,val){
				let workItem=this.groupworkItem
				if(workItem.syque_typ_id==1||workItem.syque_typ_id==5){
					//单选题或判断题
					workItem.stque_stuanswer=[val]
				}else if(workItem.syque_typ_id==2){
					//多选题
					if(this.getopt(val,workItem.stque_stuanswer)){
						workItem.stque_stuanswer.splice(workItem.stque_stuanswer.indexOf(val),1)
					}else{
						workItem.stque_stuanswer.push(val)
					}
				}
				this.homework_list[this.papIndex].children_question[this.topicIndex]=workItem
				this.setGroupworkItem(workItem)
				this.$forceUpdate();
			},
			//页面大题切换上一题
			changePrior(){
				if(this.papIndex>0){
					//题类型+1
					this.papIndex=this.papIndex-1
					//已经是第一个题了，判断上一个大类题型  不是组合提的情况下
					if(this.homework_list[this.papIndex].syque_typ_id!=14){
						//替换值
						this.setHomeworkItem(this.homework_list[this.papIndex])
					}else{
						//小题下标重置
						this.grouTopicIndex=0
						//替换值
						this.setHomeworkItem(this.homework_list[this.papIndex])
						this.setGroupworkItem(this.homework_list[this.papIndex].children_question[0])
					}
				}else{
					//组合提填充
					this.$message({
						message: '没有上一题了',
						type: 'warning'
					});
				}
			},
			//页面大题切换下一题
			changeNext(){
				//判断下一个类型是否也是最后一个 true还有下一个类型
				if(this.homework_list.length>this.papIndex+1){
					//题类型+1
					this.papIndex=this.papIndex+1
					//判断是否是组合提
					if(this.homework_list[this.papIndex].syque_typ_id!=14){
						//替换值
						this.setHomeworkItem(this.homework_list[this.papIndex])
					}else{
						//组合提填充
						//小题下标重置
						this.grouTopicIndex=0
						this.setHomeworkItem(this.homework_list[this.papIndex])
						this.setGroupworkItem(this.homework_list[this.papIndex].children_question[0])
					}
				}else{
					this.$message({
						message: '已经是最后一题',
						type: 'warning'
					});
				}
			},
			//页面小题切换上一题
			changeGrouPrior(){
				//小题是否是第一个  true还有上一小题
				if(this.grouTopicIndex>0){
					//回到上一小题
					// 切换小题
					//还有上一题
					this.grouTopicIndex=this.grouTopicIndex-1
					this.setGroupworkItem(this.homework_list[this.papIndex].children_question[this.grouTopicIndex])
				}else{
					this.changePrior()
				}
			},
			//页面小题切换下一题
			changeGrouNext(){
				// 判断组合提里面的大题里面的小题是否还有下一题 true还有下一小题
				if(this.homework_list[this.papIndex].children_question.length>this.grouTopicIndex+1){
					// 切换小题
					//还有下一题
					this.grouTopicIndex=this.grouTopicIndex+1
					this.setGroupworkItem(this.homework_list[this.papIndex].children_question[this.grouTopicIndex])
				}else{
					this.changeNext()
				}
			},
			
			//试题列表 点击试题类表里面某个值
			clickQuestion(index,index2){
				this.showAnswer=false
				this.papIndex=index
				this.grouTopicIndex=0
				this.setHomeworkItem(this.homework_list[this.papIndex])
				//不是多选题
				if(this.homework_list[this.papIndex].syque_typ_id==14){
					this.setGroupworkItem(this.homework_list[this.papIndex].children_question[this.grouTopicIndex])
				}
			},
			//清空单题的答案，切换下一题的时候input框不带刷新功能
			setHomeworkItem(homeworkItem){
				if(homeworkItem.stque_stuanswer&&homeworkItem.stque_stuanswer.length<1&&(homeworkItem.syque_typ_id==3||homeworkItem.syque_typ_id==4||homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8)){
					homeworkItem.children_question.map((iten,index)=>{
						homeworkItem.stque_stuanswer[index]=homeworkItem.stque_stuanswer[index]?homeworkItem.stque_stuanswer[index]:""
					})
				}
				this.homeworkItem=homeworkItem
			},
			//清空单题的答案，切换下一题的时候input框不带刷新功能
			setGroupworkItem(groupworkItem){
				if(groupworkItem.stque_stuanswer&&groupworkItem.stque_stuanswer.length<1&&(groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==4||groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8)){
					groupworkItem.question_child_item.map((iten,index)=>{
						groupworkItem.stque_stuanswer[index]=groupworkItem.stque_stuanswer[index]?groupworkItem.stque_stuanswer[index]:""
					})
				}
				this.groupworkItem=groupworkItem
			},
			goPage(){
                this.$confirm('已经是最后一题，确认离开', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
				}).then(() => {
                    this.$router.push({
                        name:"MISTAKES_INDEX",
                        params: {}
                    })
                })
				
			},
			//掌握
			async graspTopic(type){
				this.graspLoading=true
				let obj={stque_status:type,sccla_id:this.sccla_id}
				//组合提取小题id
				if(this.homeworkItem.syque_typ_id==14){
					obj.stque_id=this.homeworkItem.children_question[this.grouTopicIndex].stque_id
				}else{
					obj.stque_id=this.homeworkItem.stque_id
				}
				let { data } = await is_grasp(obj);
				this.homeworkItem.grasp=type
				this.homework_list[this.papIndex].grasp=type
				this.showGrasp=false
				this.graspLoading=false
			},
			//主观判断 stque_issure选择的结果  type 1大题 2小题 id题id
			async setStqueIssure(stque_issure,type,id){
				if(this.selectAnswerLoading){return false}
				this.selectAnswerLoading=true
				
				let { data } = await stu_exactness({
					stque_id:id,
					stque_issure:stque_issure,
					sccla_id:this.sccla_id,
				});
				this.selectAnswerLoading=false;
				let homework_list=this.homework_list
				let homeworkItem=this.homeworkItem
				let groupworkItem = this.groupworkItem
				if(type==1){
					//大题设置
					homeworkItem.stque_issure=stque_issure
				}else{
					//子题设置
					groupworkItem.stque_issure=stque_issure
					homework_list[this.papIndex].children_question[this.grouTopicIndex]=groupworkItem
				}
				homework_list[this.papIndex]=homeworkItem
				//查看用户是否已掌握
				if(stque_issure==1&&homework_list[this.papIndex].grasp!=20){
					this.showGrasp=true
				}else{
					homework_list[this.papIndex].grasp=20
				}
				this.setGroupworkItem(groupworkItem)
				this.setHomeworkItem(homeworkItem)
				this.homework_list=homework_list
			},
			
			/**
			 * 图片上传成功
			 */
			uploadImgSuccess({ data, el },type) {
				if (this.$isEmpty(data)) {
					return this.$message.error(msg);
				}
				let homework_list=this.homework_list
				let homeworkItem=this.homeworkItem
				let groupworkItem = this.groupworkItem
				if(type==1){
					if(homeworkItem.file){
						homeworkItem.file.push(data.file)
					}else{
						homeworkItem.file=[data.file]
					}
					homework_list[this.papIndex]=homeworkItem
					this.setHomeworkItem(homeworkItem)
					this.homework_list=homework_list
				}else{
					if(groupworkItem.file){
						groupworkItem.file.push(data.file)
					}else{
						groupworkItem.file=[data.file]
					}
					homework_list[this.papIndex].children_question[this.grouTopicIndex]=groupworkItem
					this.setGroupworkItem(groupworkItem)
					this.homework_list=homework_list
				}
				this.$forceUpdate()
			},
			//删除图片 type 1大题 2小题
			deleteImg(index,type){
				let homework_list=this.homework_list
				let homeworkItem=this.homeworkItem
				let groupworkItem = this.groupworkItem
				if(type==1){
					homeworkItem.file.splice(index,1)
					homework_list[this.papIndex]=homeworkItem
					this.setHomeworkItem(homeworkItem)
				}else{
					groupworkItem.file.splice(index,1)
					homework_list[this.papIndex].children_question[this.grouTopicIndex]=groupworkItem
					this.setGroupworkItem(groupworkItem)
				}
				this.$refs.imgUploader.uploadFiles.splice(index, 1);
				this.homework_list=homework_list
				console.log(this.groupworkItem)
				this.$forceUpdate()
			},
			// 返回正确答案结果
			getResults(list){
				let val=[]
				list.map(item=>{ 
					if(item.stque_ite_isanswer==10){
						val.push(item.stque_ite_code) 
					};
				})
				return val.toString()
			}
      
    },
}
</script>